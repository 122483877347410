import { combineReducers } from 'redux'
import { SET_EVENTS, SET_MONTH } from '!/actions/calendarActions'

const events = (state = [], action) => {
  switch (action.type) {
    case SET_EVENTS:
      return action.payload.events
    default:
      return state
  }
}

const month = (state = '', action) => {
  switch (action.type) {
    case SET_MONTH:
      return action.payload.month
    default:
      return state
  }
}

const entities = combineReducers({
  events,
})

const calendarReducer = combineReducers({
  entities,
  month,
})

export default calendarReducer
