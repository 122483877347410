import React from 'react'

const Notice = (props) => {
  if (props.m && props.m != "") {
    return (
      <div className={`alert ${props.extraClasses}`}>
        { props.m }
        <button type="button" className="close" onClick={props.dismiss}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    )
  } else {
    return (null)
  }
}

const ErrorNotice = (props) => (
  <Notice m={props.error} extraClasses="alert-danger" dismiss={props.dismiss} />
)

const SuccessNotice = (props) => (
  <Notice m={props.success} extraClasses="alert-success" dismiss={props.dismiss} />
)

export default Notice
export { ErrorNotice, SuccessNotice }
