import { connect } from 'react-redux'
import _ from 'lodash'

import PerformanceRow from '!/components/PerformanceRow'

import { makePerformanceOfIdWithPiece } from '!/selectors/performances'
import { piecesById } from '!/selectors/pieces'
import { artistsById } from '!/selectors/artists'
import { 
  SHOW_MODAL, 
  DESTROY_PERFORMANCE,
  REORDER_PERFORMANCE,
} from '!/actions/performanceTableActions'

const mapStateToProps = (state, props) => {
  let performance = makePerformanceOfIdWithPiece()(state, props)
  let artists = artistsById(state)

  let filteredAssociatedArtists = _.filter(
    _.get(performance, 'associatedArtists'),
    aa => !aa.destroy
  )

  let associatedArtistsString = _.map(filteredAssociatedArtists, aa => {
    let slug = ''
    if (aa.artistRole) {
      slug = ` (${aa.artistRole})`
    }

    let artist = artists[aa.artistId]
    if (artist) {
      slug = `${artist.name}${slug}`
    }
    return slug
  }).join(', ')

  return {
    performance,
    associatedArtistsString,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  let setModalId = () => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        id: props.id
      }
    })
  }

  let destroyPerformance = () => {
    dispatch({
      type: DESTROY_PERFORMANCE,
      payload: {
        id: props.id
      }
    })
  }

  let reorderPerformance = (id, target, direction) => {
    dispatch({
      type: REORDER_PERFORMANCE,
      payload: {
        id,
        target,
        direction
      }
    })
  }

  return {
    setModalId,
    destroyPerformance,
    reorderPerformance,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceRow)

