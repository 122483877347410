import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import StripeFestivalPass from 'components/StripeFestivalPass'

class FestivalPassPage extends React.Component {
  constructor(props) {
    super(props)
    this.authenticityToken = document.querySelector('[name="csrf-token"]').content
  }

  render() {
    return (
      <StripeProvider apiKey={this.props.apiKey}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <Elements>
                <StripeFestivalPass authenticityToken={this.authenticityToken} />
              </Elements>
            </div>
          </div>
        </div>
      </StripeProvider>
    )
  }
}

export default FestivalPassPage
