import React from 'react'
import { CardElement, injectStripe } from 'react-stripe-elements'
import PaymentRequestForm from 'components/PaymentRequestForm'
import AddressBlock from "components/AddressBlock"
import { ErrorNotice, SuccessNotice } from "components/Notice"
import { chargeOrder, handleFormChange, createFestivalPassOrder } from "tools/stripeCharges"
import { formContainerStyle, formStyle } from "tools/stripeStyles"
import numeral from 'numeral'

const amountToCurrency = amount => numeral(amount * 0.01).format('$0,0.00')

class StripeFestivalPass extends React.Component {
  constructor(props) {
    super(props)
    this.handleFormChange = handleFormChange.bind(this)
    this.createFestivalPassOrder = createFestivalPassOrder.bind(this)
    this.chargeOrder = chargeOrder.bind(this)
    this.state = {
      active: 0,
      fname: "",
      lname: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      amount: 0,
      error: "",
      quantity: 1,
      inProgress: false,
      order: null,
      success: "",
      complete: false,
    }
  }

  render() {
    if (this.state.complete) {
      return (
        <div>
          <h1>Thank you!</h1>
          <p>{this.state.success}</p>
          <p>You'll be able to pick up your pass at the first event you attend. Your name is on the list! If you have any questions or concerns, please email <a href="mailto:andrew@indexical.org">andrew@indexical.org</a>.</p>
        </div>
      )
    } else if (this.state.order) {
      return (
        <div>
          <h2>Checkout for { this.state.order.shipping.name }</h2>
          <h3>Items</h3>
          <table className="table">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th>Amt</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.order.items.map(item => {
                  if (item.quantity) {
                    return (
                      <tr key={item.description}>
                        <td>{item.description}</td>
                        <td>{item.quantity}</td>
                        <td>{amountToCurrency(item.amount)}</td>
                      </tr>
                    ) 
                  } else {
                    return null
                  }
                })
              }
              <tr key="order-total">
                <th scope="row">Total</th>
                <td />
                <td>{ amountToCurrency(this.state.order.amount) }</td>
              </tr>
            </tbody>
          </table>
          <div className="address-confirmation">
            <h3>Purchaser</h3>
            { this.state.order.shipping.name }<br />
            { this.state.order.shipping.address.line1 }<br />
            { 
              (() => { 
                if (this.state.order.shipping.address.line2) {
                  return (
                    <>
                      { this.state.order.shipping.address.line2 }<br />
                    </>
                  )
                } else {
                  return null
                }
              })()
            }
            { this.state.order.shipping.address.city }, { this.state.order.shipping.address.state } { this.state.order.shipping.address.postal_code }<br />
          </div>
          <div className="form-group my-3" style={formContainerStyle} >
            <CardElement style={formStyle} />
          </div>
          <ErrorNotice error={this.state.error} dismiss={() => this.setState({error: ""})} />
          <SuccessNotice success={this.state.success} dismiss={() => this.setState({success: ""})} />
          <button className="btn btn-block btn-lg" 
            onClick={this.chargeOrder} disabled={this.state.inProgress || this.state.complete}>
            Check out
          </button>
        </div>
      )
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <h1>Digital Alchemy Festival Pass</h1>
              <p>Now available, the Digital Alchemy All-Access Member Pass is just for Indexical members. For $29, you'll ensure you have a spot for the whole series, plus some special extras.</p>
            </div>
            <div className="col-md-3 col-sm-2 d-none d-sm-block">
              <img src="https://www.indexical.org/system/images/images/000/000/036/sm/Digital_Alchemy_Rune.png?1565889168" alt="Digital Alchemy Rune" />
            </div>
            <div className="col-md-9 col-sm-10">
              <p>
                The pass includes:
              </p>
              <ul>
                <li>Admission and reserved seating for Digital Alchemy events</li>
                <li>A free drink at the bar for events at Wind River & Radius Gallery</li>
                <li>Invitations to receptions with Digital Alchemy artists</li>
              </ul>
              <p>
                Sign up below to purchase the pass. If you're not a member yet, head to <a href="//withfriends.co/indexical/join">Withfriends</a> and join for as low as $3/month.
              </p>
            </div>
          </div>
          <form>
            <AddressBlock handleFormChange={this.handleFormChange} {...this.state} />
            <div className="form-group row">
              <label className="col-sm-2 col-form-label" htmlFor="quantity">
                # of passes
              </label>
              <div className="col-sm-10">
                <input id="quantity" 
                  type="number" 
                  className="form-control"
                  value={this.state.quantity} 
                  onChange={this.handleFormChange('quantity')} />
              </div>
            </div>
            <ErrorNotice error={this.state.error} dismiss={() => this.setState({error: ""})} />
            <SuccessNotice success={this.state.success} dismiss={() => this.setState({success: ""})} />
            <button className="btn btn-lg btn-block" onClick={this.createFestivalPassOrder} disabled={this.state.inProgress}>
              Proceed to checkout
            </button>
          </form>
        </div>
      )
    }
  }
}

export default injectStripe(StripeFestivalPass)
