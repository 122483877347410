// # General Action Definitions  
//
// What follows is a doc outline for the general semantics of the actions. We
// assume that all are Flux Standard Actions (FSA) as oulined in [the following
// repo](https://github.com/redux-utilities/flux-standard-action).
//
// ## SET_<MODELS>
//
// Indicates that an entire element should be wiped and re-assigned based on the
// incoming action.payload.<models>.
//
// ## CREATE_<MODEL>
//
// Indicates that a single element should be created and added to the
// collection, from incoming action.payload.<model>.
//

export const SET_ARTISTS = 'SET_ARTISTS' 
export const SET_PERFORMANCES = 'SET_PERFORMANCES'
export const SET_PIECES = 'SET_PIECES'
export const SET_TAGS = 'SET_TAGS'

export const CREATE_PERFORMANCE = 'CREATE_PERFORMANCE'
export const UPDATE_PERFORMANCE = 'UPDATE_PERFORMANCE'
export const DESTROY_PERFORMANCE = 'DESTROY_PERFORMANCE'
export const REORDER_PERFORMANCE = 'REORDER_PERFORMANCE'
export const SET_TAG_LIST = 'SET_TAG_LIST'
export const CREATE_TAG = 'CREATE_TAG'
export const DESTROY_TAG = 'DESTROY_TAG'

export const DESTROY_ASSOCIATED_ARTIST = 'DESTROY_ASSOCIATED_ARTIST'

// # Display-oriented actions
//
// These are specific to the display of the screen
//

export const SHOW_MODAL = 'SHOW_MODAL'
export const UPDATE_MODAL_ASSOCIATED_ARTIST = 'UPDATE_MODAL_ASSOCIATED_ARTIST'
export const DESTROY_MODAL_ASSOCIATED_ARTIST = 'DESTROY_MODAL_ASSOCIATED_ARTIST'
export const HIDE_MODAL = 'HIDE_MODAL'

