import { connect } from 'react-redux'

import PerformanceModal from '!/components/PerformanceModal'
import _ from 'lodash'

import { piecesById } from '!/selectors/pieces'
import { filteredPieces } from '!/selectors/filters'
import { artistsById } from '!/selectors/artists'
import { performanceOfModalIdWithPiece } from '!/selectors/performances'
import { modalFormAssociatedArtist, modalId } from '!/selectors/display'

import { 
  makeUpdatePerformanceArtist,
  makeUpdatePerformancePiece,
  makeUpdatePerformanceAssociatedArtist,
} from '!/thunks/makeUpdatePerformance'

import { 
  UPDATE_MODAL_ASSOCIATED_ARTIST,
  DESTROY_ASSOCIATED_ARTIST,
  HIDE_MODAL,
} from '!/actions/performanceTableActions'

const mapStateToProps = (state, props) => {
  let performance = performanceOfModalIdWithPiece(state)
  let artists = _.sortBy(artistsById(state), artist => artist.name)
  let pieces = _.sortBy(piecesById(state), piece => piece.text)
  let piecesByArtist = _.sortBy(filteredPieces(state), piece => piece.title)
  let associatedArtist = modalFormAssociatedArtist(state)

  return {
    performance,
    pieces,
    piecesByArtist,
    artists,
    associatedArtist,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  let hideModal = () => {
    dispatch({
      type: HIDE_MODAL,
    })
  }

  let updateArtist = id => {
    return event => dispatch(makeUpdatePerformanceArtist(id, event))
  }

  let updatePiece = id => {
    return event => dispatch(makeUpdatePerformancePiece(id, event))
  }

  let addAssociatedArtist = id => {
    return event => dispatch(makeUpdatePerformanceAssociatedArtist(id, event))
  }

  let updateFormAssociatedArtist = event => {
    dispatch({
      type: UPDATE_MODAL_ASSOCIATED_ARTIST,
      payload: {
        artistId: event.target.value,
      }
    })
  }

  let updateFormAssociatedArtistRole = event => {
    dispatch({
      type: UPDATE_MODAL_ASSOCIATED_ARTIST,
      payload: {
        artistRole: event.target.value,
      }
    })
  }

  let makeDestroyAssociatedArtist = id => {
    return event => {
      dispatch((dispatch, getState) => {
        let performanceId = modalId(getState())
        dispatch({
          type: DESTROY_ASSOCIATED_ARTIST,
          payload: {
            id,
            performanceId
          }
        })
      })
    }
  }

  return {
    hideModal,
    updateArtist,
    updatePiece,
    addAssociatedArtist,
    updateFormAssociatedArtist,
    updateFormAssociatedArtistRole,
    makeDestroyAssociatedArtist,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceModal)

