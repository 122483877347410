import 'whatwg-fetch'

import _ from 'lodash'
import '!/tools/lodashMixins'
import { SET_TAGS, SET_TAG_LIST} from '!/actions/performanceTableActions'

export const loadPerformableTags = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const target = state.paths.performablePath

    try {
      const response = await fetch(target, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok || response.status == 304) {
        const performable = _.deeply(_.mapKeys) 
          (await response.json(), (v, k) => _.camelCase(k))

        for (let tag of performable.tags) {
          tag.taggableTagId = _.find(performable.taggableTags, (el) => (
            el.tagId === tag.id
          ))?.id
        }

        dispatch({
          type: SET_TAGS,
          payload: {
            tags: performable.tags,
          },
        })
      }

    } catch(error) {
      window.Rollbar.error("Error retrieving tags information", error)
    }
  }

}

export default () => {
  return async (dispatch, getState) => {
    const state = getState()
    const target = state.paths.tagListPath

    try {
      const response = await fetch(target, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok || response.status == 304) {
        const tagList = _.deeply(_.mapKeys) 
          (await response.json(), (v, k) => _.camelCase(k))

        dispatch({
          type: SET_TAG_LIST,
          payload: {
            tagList,
          },
        })
      }

    } catch(error) {
      window.Rollbar.error("Error retrieving tags information", error)
    }
  }
}

