import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'

import StripeCard from 'components/StripeCard'

class DonatePage extends React.Component {
  constructor(props) {
    super(props)
    this.authenticityToken = document.querySelector('[name="csrf-token"]').content
  }

  render() {
    return (
      <StripeProvider apiKey={this.props.apiKey}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12">
              <p>You can make a donation to directly support Indexical's programming right here! All donations are tax-deductible to the extent allowed by law.</p>
              <Elements>
                <StripeCard authenticityToken={this.authenticityToken} />
              </Elements>
            </div>
          </div>
        </div>
      </StripeProvider>
    )
  }
}

export default DonatePage
