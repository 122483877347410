import React, { useState } from 'react'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { DateTime } from 'luxon'
import Calendar from "!/containers/Calendar"
import calendarReducer from "!/reducers/calendarReducer"
import loadEvents from "!/thunks/loadEvents"

const CalendarWidget = ({ startYear, startMonth, events }) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const month = DateTime.local(startYear, startMonth, 1)
  const initialState = {
    entities: { events },
    month,
  }
  const store = createStore(calendarReducer, initialState, composeEnhancers(applyMiddleware(thunk)))

  store.dispatch(loadEvents(month))

  return (
    <Provider store={store}>
      <Calendar startYear={startYear} startMonth={startMonth} />
    </Provider>
  )
}

export default CalendarWidget
