import React, { Component } from 'react'

const AddressBlock = (props) => (
  <div className="address-block">
    <div className="form-row row">
      <div className="col-md-6">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="fname" placeholder="First Name" value={props.fname} onChange={props.handleFormChange('fname')} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="lname" placeholder="Last Name" value={props.lname} onChange={props.handleFormChange('lname')}  />
        </div>
      </div>
      <div className="col-12">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="email" placeholder="music_fan@compuserve.net" value={props.email} onChange={props.handleFormChange('email')}  />
        </div>
      </div>
    </div>
    <div className="form-row">
      <div className="col-12">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="line1" placeholder="Street Address" value={props.line1} onChange={props.handleFormChange('line1')}  />
        </div>
      </div>
      <div className="col-12">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="line2" placeholder="Street Address 2" value={props.line2} onChange={props.handleFormChange('line2')}  />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="city" placeholder="City" value={props.city} onChange={props.handleFormChange('city')}  />
        </div>
      </div>
      <div className="col-md-2">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="state" placeholder="State" value={props.state} onChange={props.handleFormChange('state')}  />
        </div>
      </div>
      <div className="col-md-4">
        <div className="form-group my-2">
          <input type="text" className="form-control" id="zip" placeholder="ZIP" value={props.zip} onChange={props.handleFormChange('zip')}  />
        </div>
      </div>
    </div>
  </div>
)

export default AddressBlock
