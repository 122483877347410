import { connect } from "react-redux"
import { DateTime } from "luxon"

import Calendar from "!/components/Calendar"
import { eventsByDate } from "!/selectors/events"
import { SET_MONTH } from "!/actions/calendarActions"
import loadEvents from "!/thunks/loadEvents"

const mapStateToProps = (state, _props) => {
  const events = eventsByDate(state)
  const month = state.month
  return {
    events,
    month,
  }
}

const mapDispatchToProps = (dispatch, _props) => {
  const setMonth = month => {
    dispatch({
      type: SET_MONTH,
      payload: {
        month,
      }
    })

    const newQuery = new URLSearchParams({ month: month.month, year: month.year })
    history.pushState({month: month.month, year: month.year}, null, `${window.location.pathname}?${newQuery.toString()}`)

    dispatch(loadEvents(month))
  }

  return {
    setMonth,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
