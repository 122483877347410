import React, { useState } from "react"
import ReactDOM from "react-dom"

const TagsTable = (props) => {
  const [tagName, setTagName] = useState("")

  return (
    <div className="border my-2 p-3">
      <h4>Tags</h4>
      <ul>
        {
          props.tags.filter(tag => !tag.destroy).map(tag => 
            <li onClick={props.destroyTag(tag)} className="badge badge-primary mx-2" key={tag.name}>{tag.name}</li>
          )
        }
      </ul>
      <input className="form-control text"
        list="tag-names"
        type="text"
        name="tag" 
        value={tagName}
        onChange={(ev) => setTagName(ev.target.value)}
      />
      <datalist id="tag-names">
        {
          props.tagList.map(tag => 
            <option key={tag.name} value={tag.name} label={tag.name}>
              {tag.name}
            </option>
          )
        }
      </datalist>
      <button onClick={props.createTag(tagName)}>Add Tag</button>
    </div>
  )
}

export default TagsTable
