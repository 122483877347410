import { connect } from 'react-redux'

import { 
  performances as performancesSelector, 
  makePerformanceOfIdWithPiece,
} from '!/selectors/performances'

import { tags as tagsSelector } from '!/selectors/tags'

import InvisibleFields from '!/components/InvisibleFields'

const idMatch = new RegExp('^#')

const mapStateToProps = (state, props) => {
  let type = state.page.type
  let performances = performancesSelector(state).map(performance => {
    let p = makePerformanceOfIdWithPiece()(state, { id: performance.id })
    if (performance.id.toString().match(idMatch)) {
      // Process as a new performance
      return {
        ...p,
        key: performance.id,
        id: "",
      }
    } else {
      // Edit the previous performance
      return {
        ...p,
        key: performance.id,
      }
    }
  })

  const tags = tagsSelector(state)

  return {
    performances,
    tags,
    type
  }
}

export default connect(mapStateToProps)(InvisibleFields)

