import React from 'react'
import CalendarSquare from './CalendarSquare'

const Calendar = ({ events, month, setMonth }) => {
  const days = [...Array(month.daysInMonth)].map((o, day) => {
    const date = month.set({day: day + 1})
    return (
      <CalendarSquare key={day + 1} date={date} events={events[date.toSQLDate()]} />
   )
  })

  const leaderDays = [...Array(month.weekday % 7)]
    .map((o, day) => <CalendarSquare key={day - 7} />)
  const afterDays = [...Array(7 - month.set({ day: month.daysInMonth }).weekday % 7 - 1)]
    .map((o, day) => <CalendarSquare key={day - 14} />)

  const prevMonth = () => {
    setMonth(month.minus({ month: 1 }))
    return null
  }
  const nextMonth = () => {
    setMonth(month.plus({ month: 1 }))
    return null
  }

  return (
    <div className="calendar">
      <div className="calendar-month d-flex justify-content-between">
        <div className="calendar-name">
          {month.monthLong} {month.year}
        </div>
        <div className="calendar-arrows">
          <a onClick={prevMonth}>&lt;</a>
          <a onClick={nextMonth}>&gt;</a>
        </div>
      </div>
      <ul>
        { leaderDays }
        { days }
        { afterDays }
      </ul>
    </div>
  )
}

export default Calendar
