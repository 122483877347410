import { createSelector } from 'reselect'
import _ from 'lodash'

import { artistsById } from '!/selectors/artists'

export const display = state => state.display

export const modal = createSelector(display, d => _.get(d, 'modal'))
export const modalId = createSelector(modal, m => _.get(m, 'id'))
export const modalForm = createSelector(modal, m => _.get(m, 'form'))

export const modalFormAssociatedArtist = createSelector(
  modalForm,
  artistsById,
  (form, arts) => {
    let id = _.get(form, 'associatedArtist.id')
    let artist = arts[_.get(form, 'associatedArtist.artistId')]
    let artistRole = _.get(form, 'associatedArtist.artistRole')
    return {
      id,
      artist,
      artistRole,
    }
  }
)

