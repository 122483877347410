import React, { Component } from 'react'
import _ from 'lodash'

export default class InvisibleFields extends Component {
  render() {
    let type = this.props.type
    return (
      <div>
        {
          _.map(this.props.tags.filter(tag => tag.name !== ""), (tag, idx) => 
            <span key={tag.name}>
              {
                !!tag.taggableTagId ? (
                  <input 
                    type="hidden" 
                    name={`${type}[taggable_tags_attributes][][id]`}
                    value={tag.taggableTagId} 
                  />
                ) : null
              }
              {
                !!tag.destroy ? (
                  <input 
                    type="hidden" 
                    name={`${type}[taggable_tags_attributes][][_destroy]`}
                    value="1"
                  />
                ) : null
              }
              {
                !!tag.id ? (
                  <input 
                    type="hidden" 
                    name={`${type}[taggable_tags_attributes][][tag_id]`}
                    value={tag.id} 
                  />
                ) : (
                  <input 
                    type="hidden" 
                    name={`${type}[taggable_tags_attributes][][tag_attributes][name]`}
                    value={tag.name} 
                  />
                )
              }
            </span>
          )
        }
        {
          _.map(this.props.performances, (performance, idx) => {
            let pieceAttributes
            if (_.get(performance, 'piece.id') === undefined) {
              let artistAttributes
              if (_.get(performance, 'piece.artist.id') === undefined) {
                artistAttributes = (
                  <React.Fragment>
                    <input
                      type="hidden"
                      name={`${type}[performances_attributes][][piece_attributes][artist_attributes][name]`}
                      value={_.get(performance, 'piece.artist.name', '')} />
                    <input
                      type="hidden"
                      name={`${type}[performances_attributes][][piece_attributes][artist_attributes][id]`}
                      value={_.get(performance, 'piece.artist.id', '')} />
                  </React.Fragment>
                )
              }
              
              pieceAttributes = (
                <React.Fragment>
                  <input
                    type="hidden"
                    name={`${type}[performances_attributes][][piece_attributes][artist_id]`}
                    value={_.get(performance, 'piece.artist.id', '')} />
                  { artistAttributes }
                  <input
                    type="hidden"
                    name={`${type}[performances_attributes][][piece_attributes][title]`}
                    value={_.get(performance, 'piece.title', '')} />
                </React.Fragment>
              )
            }

            return (
              <div key={performance.key}>
                <input
                  type="hidden" 
                  name={`${type}[performances_attributes][][id]`}
                  value={_.get(performance, 'id', '')} />
                <input 
                  type="hidden" 
                  name={`${type}[performances_attributes][][piece_id]`}
                  value={_.get(performance, 'piece.id', '')} />
                { pieceAttributes }
                <input
                  type="hidden"
                  name={`${type}[performances_attributes][][order_position]`}
                  value={idx} />
                <input
                  type="hidden"
                  name={`${type}[performances_attributes][][_destroy]`}
                  value={performance.destroy} />

                {
                  _.map(performance.associatedArtists, (associatedArtist) => {
                    let id = associatedArtist.id
                    let baseString = `${type}[performances_attributes][][associated_artists_attributes][]`

                    if (!associatedArtist.id.toString().match("#")) {
                      var idElement = (
                        <input
                          type="hidden"
                          name={`${baseString}[id]`}
                          value={associatedArtist.id}
                        />
                      )
                    } else {
                      var idElement = undefined
                    }

                    return (
                      <React.Fragment key={associatedArtist.id}>
                        { idElement }
                        <input
                          type="hidden"
                          name={`${baseString}[artist_id]`}
                          value={associatedArtist.artistId}
                        />
                        <input
                          type="hidden"
                          name={`${baseString}[artist_role]`}
                          value={associatedArtist.artistRole}
                        />
                        <input
                          type="hidden"
                          name={`${baseString}[_destroy]`}
                          value={associatedArtist.destroy}
                        />
                      </React.Fragment>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    )
  }
}

