import React, { Component } from "react"
import ReactDOM from "react-dom"

import PerformanceRow from "!/containers/PerformanceRow"
import PerformanceModal from '!/containers/PerformanceModal'
import NewPerformanceButton from '!/containers/NewPerformanceButton'
import InvisibleFields from '!/containers/InvisibleFields'

class PerformanceTable extends Component {
  render() {
    return (
      <div>
        <table className="performances table">
          <thead>
            <tr>
              <th>Piece</th>
              <th>Associated Artists</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.performanceIds.map(id => <PerformanceRow key={id} id={id} />)
            }
          </tbody>
        </table>
        <NewPerformanceButton />
        <PerformanceModal />
        <InvisibleFields />
      </div>
    )
  }
}

export default PerformanceTable
