import React, { Component } from "react"

import { FaTimes, FaPlus } from 'react-icons/fa'
import _ from 'lodash'

export default class PerformanceModal extends Component {
  componentDidUpdate() {
    let e = $('#performanceModal')

    // Register event handlers for bootstrap modal
    if (e) { 
      e.modal('show') 
      e.on('hidden.bs.modal', this.props.hideModal)
    }
  }

  render() {
    if (this.props.performance) {
      return (
        <div className="modal" id="performanceModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3>Performance Editor</h3>
              </div>
              <div className="modal-body">
                <PerformanceArtist {...this.props} />
                <PerformancePiece {...this.props} />
                <PerformanceAssociatedArtistForm {...this.props} />
                <PerformanceAssociatedArtistsList {...this.props} />
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

const PerformanceArtist = (props) => {
  return (
    <div className="form-group">
      <label className="control-label text" htmlFor="artist">
        Artist
      </label>
      <input className="form-control text"
        list="artist-names"
        type="text"
        name="artist"
        value={_.get(props, 'performance.piece.artist.name')} 
        onChange={props.updateArtist(_.get(props, 'performance.id'))} />
      <datalist id="artist-names">
        {
          _.map(props.artists, (artist) => {
            return (
              <option key={artist.id} value={artist.name} label={artist.name}>
                {artist.name}
              </option>
            )
          })
        }
      </datalist>
    </div>
  )
}

const PerformancePiece = (props) => {
  return (
    <div className="form-group">
      <label className="control-label text" htmlFor="piece">
        Piece
      </label>
      <input className="form-control text"
        list="piece-titles"
        type="text"
        name="piece_title"
        value={_.get(props, 'performance.piece.title', '')} 
        onChange={props.updatePiece(_.get(props, 'performance.id'))} />
      <datalist id="piece-titles">
        {
          _.map(props.piecesByArtist, (piece, id) => {
            return (
              <option key={id} value={piece.title} label={piece.title}>
                {piece.title}
              </option>
            )
          })
        }
      </datalist>
    </div>
  )
}

const PerformanceAssociatedArtistForm = (props) => {
  return (
    <div className="form-group">
      <div className="form-group">
        <label className="control-label" htmlFor="associatedArtist">
          Select Associated Artist
        </label>
        <select className="form-control"
          name="associatedArtist"
          value={_.get(props, 'associatedArtist.artist.id', '')}
          onChange={props.updateFormAssociatedArtist}>
        <option key="0" value="" label="" />
          {
            _.map(props.artists, (artist, id) => {
              return (
                <option key={id} value={artist.id} label={artist.name}>
                  {artist.name}
                </option>
              )
            })
          }
        </select>
      </div>
      <div className="form-group">
        <label className="control-label" htmlFor="role">
          Enter Role for Associated Artist
        </label>
        <input className="form-control text"
          type="text"
          name="role"
          value={_.get(props, 'associatedArtist.artistRole', '')}
          onChange={props.updateFormAssociatedArtistRole}>
        </input>
      </div>
      <div className="btn btn-secondary" onClick={props.addAssociatedArtist(_.get(props, 'performance.id'))}>
        Add Associated Artist
      </div>
    </div>
  )
}

const PerformanceAssociatedArtistsList = (props) => {

  let filteredArtists = _.filter(
    _.get(props, 'performance.associatedArtists', []),
    aa => !aa.destroy
  )

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Artist</th>
          <th>Role</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          _.map(filteredArtists, aa => {
            return (
              <PerformanceAssociatedArtistRow 
                key={aa.id} 
                aa={aa} 
                destroy={props.makeDestroyAssociatedArtist(aa.id)} />
            )
          })
        }
      </tbody>
    </table>
  )
}

const PerformanceAssociatedArtistRow = (props) => {
  return (
    <tr>
      <td>{props.aa.artist.name}</td>
      <td>{props.aa.artistRole}</td>
      <td onClick={props.destroy}><FaTimes /></td>
    </tr>
  )
}
