import React, { Component } from "react"
import ReactDOM, { findDOMNode } from "react-dom"
import { DragSource, DropTarget } from 'react-dnd'

import _ from 'lodash'

const style = {
  cursor: 'move'
}

class PerformanceRow extends Component {
  render() {
    const { 
      isDragging, 
      connectDragSource, 
      connectDropTarget,
    } = this.props

    const opacity = isDragging ? 0 : 1

    return connectDragSource(
      connectDropTarget(
        <tr data-item-id={this.props.id} className="performance" style={{...style, opacity}} >
          <td className="piece">
            {_.get(this.props, 'performance.piece.artist.name', '')}: {_.get(this.props, 'performance.piece.title', '')}
          </td>
          <td className="associatedArtists">{this.props.associatedArtistsString}</td>
          <td>
            <div className="btn btn-secondary btn-small" 
              onClick={this.props.setModalId}>edit</div>
          </td>
          <td>
            <div className="btn btn-danger btn-small"
              onClick={this.props.destroyPerformance}>delete</div>
          </td>
        </tr>
      )
    )
  }
}

const cardSource = {
  beginDrag(props) {
    const item = { id: props.id }
    return item
  }
}

const collectDragSource = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

const cardTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return 
    }

    const dragId = monitor.getItem().id
    const hoverId = props.id

    if (dragId === hoverId) {
      return
    }

    const clientDelta = monitor.getDifferenceFromInitialOffset()

    if (clientDelta.y < 0) {
      props.reorderPerformance(dragId, hoverId, 'before')
    }

    if (clientDelta.y > 0) {
      props.reorderPerformance(dragId, hoverId, 'after')
    }
  }
}

const collectDropTarget = (connect) => {
  return {
    connectDropTarget: connect.dropTarget(),
  }
}


export default _.flow(
  DropTarget('performance', cardTarget, collectDropTarget),
  DragSource('performance', cardSource, collectDragSource)
)(PerformanceRow)
