import React from 'react'
import { CardElement, injectStripe } from "react-stripe-elements"
import ButtonArray from "components/ButtonArray"
import PaymentRequestForm from "components/PaymentRequestForm"
import AddressBlock from "components/AddressBlock"
import AmountBlock from "components/AmountBlock"
import { formContainerStyle, formStyle } from "tools/stripeStyles"
import { submit, charge, handleFormChange } from "tools/stripeCharges"
import { ErrorNotice, SuccessNotice } from "components/Notice.jsx"

class StripeCard extends React.Component {
  constructor(props) {
    super(props)
    this.handleFormChange = handleFormChange.bind(this)
    this.setAmount = this.setAmount.bind(this)
    this.submit = submit.bind(this)
    this.charge = charge.bind(this)
    this.state = {
      active: 0,
      fname: "",
      lname: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      email: "",
      amount: 0,
      error: "",
      inProgress: false,
      success: "",
    }
  }

  setAmount(amt) {
    return () => {
      this.setState({
        amount: amt,
        active: amt,
      })
    }
  }

  render() {
    return (
      <form>
        <AmountBlock handleFormChange={this.handleFormChange} {...this.state} />
        <AddressBlock handleFormChange={this.handleFormChange} {...this.state} />
        <ErrorNotice error={this.state.error} dismiss={() => this.setState({error: ""})} />
        <SuccessNotice success={this.state.success} dismiss={() => this.setState({success: ""})} />
        <div className="form-group my-3" style={formContainerStyle} >
          <PaymentRequestForm charge={this.charge} currentState={this.state}>
            <p>
              Looks like either you don't have a saved payment method or your
              browser doesn't support Apple Pay or Google Pay. Try entering your
              card manually below.
            </p>
          </PaymentRequestForm>
        </div>
        <div className="form-group my-3" style={formContainerStyle} >
          <CardElement style={formStyle} />
        </div>
        <button className="btn btn-block btn-lg btn-warning" onClick={this.submit} disabled={this.state.inProgress}>Donate!</button>
      </form>
    )
  }
}

export default injectStripe(StripeCard)
