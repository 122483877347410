import { createSelector } from 'reselect'
import _ from 'lodash'

import { entities } from '!/selectors/entities'
import { artistsById } from '!/selectors/artists'

export const pieces = createSelector(
  entities,
  artistsById,
  (ents, arts) => {
    let out = [ ] 

    for (let piece of ents.pieces) {
      let artist = arts[piece.artistId]

      out.push({
        ...piece,
        artist,
      })
    }

    return out
  }
)

export const piecesById = createSelector(
  pieces,
  (pcs) => {
    let out = { }

    for (let piece of pcs) {
      out[piece.id] = piece
    }

    return out
  }
)

export const piecesByArtistAndTitle = createSelector(
  pieces,
  artistsById,
  (pcs, arts) => {
    let out = { }

    for (let piece of pcs) {
      let slug = piece.title

      let artist = arts[piece.artistId]
      if (artist) {
        slug = `${artist.name}: ${slug}`
      }

      out[slug] = piece
    }

    return out
  }
)

