import React, { Component } from 'react'
import { PaymentRequestButtonElement, injectStripe } from "react-stripe-elements"

class _PaymentRequestForm extends React.Component {
  constructor(props) {
    super(props)
    const paymentRequest = props.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Donation',
        amount: 0,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    paymentRequest.on('token', ({complete, token, ...data}) => {
      props.charge({
        email: data.payerEmail,
        token: token.id,
      })
      complete('success')
    })

    paymentRequest.canMakePayment().then((result) => {
      this.setState({canMakePayment: !!result})
    })

    this.state = {
      canMakePayment: false,
      paymentRequest,
    }
  }

  render() {
    return this.state.canMakePayment ? (
      <PaymentRequestButtonElement
        paymentRequest={this.state.paymentRequest}
        className="PaymentRequestButton"
        style={{
          paymentRequestButton: {
            theme: 'light',
            height: '64px',
          },
        }}
      />
    ) : (
      <>
        { this.props.children }
      </>
    )
  }
}

export default injectStripe(_PaymentRequestForm)

