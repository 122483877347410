import React, { Component } from "react"
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext} from 'react-dnd'

import PerformanceTable from '!/containers/PerformanceTable'
import TagsTable from '!/containers/TagsTable'

import performanceTableReducer from '!/reducers/performanceTableReducer'
import loadPerformancesThunk from '!/thunks/loadPerformances'
import loadArtistsThunk from '!/thunks/loadArtists'
import loadPiecesThunk from '!/thunks/loadPieces'
import loadTagsThunk, { loadPerformableTags } from '!/thunks/loadTags'

class PerformanceEmbeddedForm extends Component {
  render() {
    const defaultStoreState = { 
      entities: { },
      paths: {
        performablePath: `/${this.props.performable_types}/${this.props.performable_id}.json`,
        performancesPath: `/${this.props.performable_types}/${this.props.performable_id}/performances.json`,
        artistsPath: "/artists.json",
        piecesPath: "/pieces.json",
        tagsPath: "/tags.json",
        tagListPath: "/tags.json",
      },
      page: {
        type: this.props.performable_type,
      },
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    const store = createStore(performanceTableReducer, defaultStoreState, composeEnhancers(applyMiddleware(thunk)))

    store.dispatch(loadPerformancesThunk())
    store.dispatch(loadArtistsThunk())
    store.dispatch(loadPiecesThunk())
    store.dispatch(loadTagsThunk())
    store.dispatch(loadPerformableTags())

    return (
      <Provider store={store}>
        <div>
          <PerformanceTable />
          <TagsTable />
        </div>
      </Provider>
    )
  }
}

export default DragDropContext(HTML5Backend)(PerformanceEmbeddedForm)

