import { createSelector } from 'reselect'
import _ from 'lodash'

import { entities } from '!/selectors/entities'
import { piecesById } from '!/selectors/pieces'
import { modalId } from '!/selectors/display'
import { artistsById } from '!/selectors/artists'
import { modal } from '!/selectors/display'

export const performanceId = (state, props) => props.id

export const performances = createSelector(
  entities,
  (ents) => {
    return ents.performances
  }
)

export const visiblePerformances = createSelector(
  performances,
  (perfs) => {
    return _.filter(perfs, p => !p.destroy)
  }
)

export const performancesById = createSelector(
  performances,
  (perfs) => {
    let out = { }

    for (let performance of perfs) {
      out[performance.id] = performance
    }

    return out
  }
)

export const performanceIds = createSelector(
  performances,
  (perfs) => _.map(perfs, 'id')
)

export const visiblePerformanceIds = createSelector(
  visiblePerformances,
  (perfs) => _.map(perfs, 'id')
)

export const makePerformanceOfId = () => {
  return createSelector(
    performancesById,
    performanceId,
    (perfs, id) => perfs[id]
  )
}

export const makePerformanceOfIdWithPiece = () => {
  return createSelector(
    performancesById,
    performanceId,
    piecesById,
    artistsById,
    modal,
    (perfs, id, pcs, artists, m) => {
      let performance = perfs[id]
      let piece = pcs[performance.pieceId]

      if (piece === undefined) {
        piece = performance.piece
      }

      if (piece === undefined) {
        let artist = _.get(artists, _.get(performance, 'pieceArtistId'))
        if (artist === undefined) {
          artist = { name: _.get(m, 'form.artist.name', '') }
        }
        let title = _.get(performance, 'pieceTitle', '')
        piece = {
          artist: artist,
          title: title,
        }
      }

      return {
        ...performance,
        piece,
      }
    }
  )
}

export const performanceOfModalIdWithPiece = createSelector(
  performancesById,
  modalId,
  piecesById,
  artistsById,
  (perfs, id, pcs, arts) => {
    if (id) {
      let performance = perfs[id]
      let piece = pcs[performance.pieceId]

      if (piece == undefined) {
        piece = {
          ...performance.piece
        }
      }

      let associatedArtists = _.map(performance.associatedArtists, aa => {
        return {
          ...aa,
          artist: arts[aa.artistId],
        }
      })

      return {
        ...performance,
        associatedArtists,
        piece,
      }
    }
  }
)

