import 'whatwg-fetch'

import _ from 'lodash'

import '!/tools/lodashMixins'
import { SET_PERFORMANCES } from '!/actions/performanceTableActions'

export default () => {
  return async (dispatch, getState) => {
    let state = getState()
    let target = state.paths.performancesPath

    try {
      let response = await fetch(target, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok || response.status == 304) {
        let performances = _.deeply(_.mapKeys)
          (await response.json(), (v, k) => _.camelCase(k))

        dispatch({
          type: SET_PERFORMANCES,
          payload: {
            performances,
          },
        })
      }

    } catch(error) {
      window.Rollbar.error("Error retrieving performance information", error)
    }
  }
}

