import _ from 'lodash'

import { 
  UPDATE_PERFORMANCE,
  DESTROY_MODAL_ASSOCIATED_ARTIST,
} from '!/actions/performanceTableActions'

import randomId from '!/tools/randomId'
import { artistsByName, artistsById } from '!/selectors/artists'
import { piecesByArtistAndTitle } from '!/selectors/pieces'
import { performancesById } from '!/selectors/performances'
import { modalId, modalForm, modalFormAssociatedArtist } from '!/selectors/display'

export const makeUpdatePerformanceArtist = (id, event) => {
  return (dispatch, getState) => {
    let state = getState()
    let artists = artistsByName(state)
    let artist = artists[event.target.value]

    if (artist) {
      dispatch({
        type: UPDATE_PERFORMANCE,
        payload: {
          id,
          piece: {
            artist
          }
        }
      })
    } else {
      dispatch({
        type: UPDATE_PERFORMANCE,
        payload: {
          id,
          piece: {
            artist: {
              name: event.target.value
            }
          }
        }
      })
    }
  }
}

export const makeUpdatePerformancePiece = (id, event) => {
  return (dispatch, getState) => {
    let state = getState()
    let currentPerformance = performancesById(state)[modalId(state)]
    let pieces = piecesByArtistAndTitle(state)
    let artist = artistsById(state)[_.get(currentPerformance, 'piece.artist.id')]

    let piece = undefined

    if (artist) {
      piece = pieces[`${artist.name}: ${event.target.value}`]
    }

    if (piece) {
      dispatch({
        type: UPDATE_PERFORMANCE,
        payload: {
          id,
          piece,
        }
      })
    } else {
      dispatch({
        type: UPDATE_PERFORMANCE,
        payload: {
          id,
          piece: {
            title: event.target.value,
            artist: _.get(currentPerformance, 'piece.artist')
          }
        }
      })
    }
  }
}

export const makeUpdatePerformanceAssociatedArtist = (id, event) => {
  return (dispatch, getState) => {
    let state = getState()
    let currentPerformance = performancesById(state)[modalId(state)]
    let associatedArtist = modalFormAssociatedArtist(state)
    
    dispatch({
      type: UPDATE_PERFORMANCE,
      payload: {
        id,
        ...currentPerformance,
        associatedArtists: [
          ...(currentPerformance.associatedArtists || []),
          {
            id: randomId(),
            artistId: associatedArtist.artist.id,
            artistRole: associatedArtist.artistRole,
          }
        ]
      }
    })

    dispatch({
      type: DESTROY_MODAL_ASSOCIATED_ARTIST,
    })
  }
}

