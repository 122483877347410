import { createSelector } from 'reselect'
import _ from 'lodash'

import { entities } from '!/selectors/entities'

// Returns list of tags attached to this event
export const tags = createSelector(
  entities,
  (ents) => {
    return ents.tags
  }
)

// Returns the full list of tags available
export const tagList = createSelector(
  entities,
  (ents) => {
    return ents.tagList
  }
)

export const tagsByName = createSelector(
  tagList,
  (t) => {
    let out = {}
    for (let tag of t) {
      out[tag.name] = tag
    }
    return out
  }
)

