import { createSelector } from "reselect"
import { DateTime } from 'luxon'
import { entities } from "!/selectors/entities"

export const events = createSelector(
  entities,
  (e) => {
    return e.events
  }
)

export const eventsByDate = createSelector(
  events,
  (e) => {
    let out = { }
    for (let event of e) {
      for (let eventTime of event.eventTimesData) {
        const dateTime = DateTime.fromISO(eventTime.time || "")
        const date = dateTime.toSQLDate()
        const eventWithTime = {
          ...event,
          thisEventTime: dateTime,
        }

        if (out[date] === undefined) {
          out[date] = [eventWithTime]
        } else {
          out[date].push(eventWithTime)
          out[date].sort((a, b) => { return a.thisEventTime.toMillis() - b.thisEventTime.toMillis() })
        }
      }
    }

    return out
  }
)

