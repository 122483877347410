import React, { Component } from 'react'
import ReactDOM from 'react-dom'

export default class NewPerformanceButton extends Component {
  render() {
    return (
      <div className="btn btn-secondary" onClick={this.props.createNewPerformance}>New Performance</div>
    )
  }
}

