import React, { Component } from 'react'

const AmountButton = (props) => (
  <div className="col-md-6 col-lg-4 col-12 my-1">
    <div className={`btn btn-block btn-outline-secondary ${(props.active ? 'active' : '')}`} onClick={ props.onClick }>
      { props.children }
    </div>
  </div>
)

export default (props) => (
  <div className="row">
    <AmountButton active={props.active == 36} onClick={props.setAmount(36)}>$36: The Star</AmountButton>
    <AmountButton active={props.active == 60} onClick={props.setAmount(60)}>$60: The Lovers</AmountButton>
    <AmountButton active={props.active == 120} onClick={props.setAmount(120)}>$120: The Herald</AmountButton>
    <AmountButton active={props.active == 240} onClick={props.setAmount(240)}>$240: The Magus</AmountButton>
    <AmountButton active={props.active == 500} onClick={props.setAmount(500)}>$500: The High Priestess</AmountButton>
    <AmountButton active={props.active == 1000} onClick={props.setAmount(1000)}>$1,000: The Wheel of Fortune</AmountButton>
    <AmountButton active={props.active == 2500} onClick={props.setAmount(2500)}>$2,500: The Chariot</AmountButton>
    <AmountButton active={props.active == 5000} onClick={props.setAmount(5000)}>$5,000: The World</AmountButton>
    <AmountButton active={false} onClick={props.setAmount(Math.floor(Math.random() * 1000))}>Surprise me!</AmountButton>
  </div>
)
