import React, { useState } from 'react'

const Search = () => {
  const [query, setQuery] = useState("")
  const [showForm, setShowForm] = useState(false)

  const updateQuery = (ev) => {
    setQuery(ev.target.value)
  }

  const submitSearch = (ev) => {
    ev.preventDefault()
    window.location = `/search?q=${query}`
  }

  return (
    <form className="search-form form-inline d-flex flex-row flex-sm-nowrap" onSubmit={submitSearch}>
      {
        showForm && (
          <input type="text"
            className="form-control mb-sm-0 mb-1"
            value={query}
            onChange={updateQuery}
            placeholder="search me" />
        )
      }
      {
        showForm ? (
          <button className="button form-control border-0" type="submit">
            <i className="fa fa-search" />
          </button>
        ) : (
          <button className="button form-control border-0" type="submit" onClick={(ev) => ev.preventDefault()}>
            <i className="fa fa-search" onClick={() => setShowForm(true)} />
          </button>
        )
      }
    </form>
  )
}

export default Search
