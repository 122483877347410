import { connect } from 'react-redux'

import { 
  CREATE_PERFORMANCE,
  SHOW_MODAL,
} from '!/actions/performanceTableActions'

import randomId from '!/tools/randomId'
import NewPerformanceButton from '!/components/NewPerformanceButton'

const mapStateToProps = (state, props) => {
  return { }
}

const mapDispatchToProps = (dispatch, props) => {
  let createNewPerformance = () => {
    let id = randomId()

    dispatch({
      type: CREATE_PERFORMANCE,
      payload: {
        id,
      }
    })

    dispatch({
      type: SHOW_MODAL,
      payload: {
        id,
      }
    })
  }

  return {
    createNewPerformance
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPerformanceButton)

