const formContainerStyle = {
  padding: '1em',
  border: '1px solid #ccc',
}

const formStyle = {
  base: {
    color: '#32325d',
    fontFamily: 'proxima-nova, "Proxima Nova", -apple-system, sans-serif',
    fontSize: '18px',
    fontWeight: 300,
    '::placeholder': {
      color: '#aab7c4'
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
}

export { formContainerStyle, formStyle }
