import { createSelector } from 'reselect'
import _ from 'lodash'

import { modal } from '!/selectors/display'
import { pieces } from '!/selectors/pieces'
import { performancesById } from '!/selectors/performances'

export const filteredPieces = createSelector(
    modal,
    pieces,
    performancesById,
    (m, pcs, perfs) => {
        let perf = perfs[m.id]
        let artistId = _.get(perf, 'piece.artist.id')
        if (artistId) {
            return _.filter(pcs, piece => piece.artist.id == artistId)
        } else {
            return pcs
        }
    }
)
