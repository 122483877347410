import React from 'react'

const AmountBlock = (props) => (
  <div className="text-center amount-block">
    <div className="input-group my-2">
      <div className="input-group-prepend">
        <span className="input-group-text">$</span>
      </div>
      <input id="donation-amount" type="number" value={props.amount} min="0" data-number-to-fixed="2" data-number-stepfactor="100" className="form-control" onChange={props.handleFormChange('amount')} placeholder="0" />
      <div className="input-group-append">
        <span className="input-group-text">.00</span>
      </div>
    </div>
  </div>
)

export default AmountBlock
