import { combineReducers } from 'redux'
import _ from 'lodash'

import {
  SET_ARTISTS,
  SET_PERFORMANCES,
  SET_PIECES,
  SET_TAGS,
  CREATE_PERFORMANCE,
  UPDATE_PERFORMANCE,
  DESTROY_PERFORMANCE,
  REORDER_PERFORMANCE,
  SET_TAG_LIST,
  CREATE_TAG,
  DESTROY_TAG,
  DESTROY_ASSOCIATED_ARTIST,
  SHOW_MODAL,
  UPDATE_MODAL_ASSOCIATED_ARTIST,
  DESTROY_MODAL_ASSOCIATED_ARTIST,
  HIDE_MODAL, 
} from '!/actions/performanceTableActions' 

const artists = (state = [], action) => { 
  switch (action.type) { 
    case SET_ARTISTS: 
      return action.payload.artists
    default:
      return state
  }
}

const performances = (state = [], action) => {
  switch (action.type) {
    case SET_PERFORMANCES:
      return action.payload.performances
    case CREATE_PERFORMANCE:
      return [
        ...state,
        action.payload,
      ]
    case UPDATE_PERFORMANCE:
      return _.map(state, p => {
        if (p.id != action.payload.id) {
          return p
        } else {
          return {
            ...p,
            ...action.payload
          }
        }
      })
    case REORDER_PERFORMANCE:
      let { id, target, direction } = action.payload
      if (id && target && direction) {
        let me = _.find(state, p => p.id == id)
        let copy = _.filter(state, p => p.id != id)
        let targetIndex = _.findIndex(copy, p => p.id == target)
        if (direction == 'before') {
          copy.splice(targetIndex, 0, me)
          return copy
        } else if (direction == 'after') {
          copy.splice(targetIndex + 1, 0, me)
          return copy
        } 
      } else {
        return state
      }
    case DESTROY_ASSOCIATED_ARTIST:
      return _.map(state, p => {
        if (p.id != action.payload.performanceId) {
          return p
        } else {
          return {
            ...p,
            associatedArtists: _.compact(_.map(p.associatedArtists, aa => {
              if (aa.id != action.payload.id) {
                return aa
              } else {
                return {
                  ...aa,
                  destroy: true
                }
              }
            }))
          }
        }
      })
    case DESTROY_PERFORMANCE:
      return _.map(state, p => {
        if (p.id != action.payload.id) {
          return p
        } else {
          return {
            ...p,
            destroy: true
          }
        }
      })
    default: 
      return state
  }
}

const pieces = (state = [], action) => {
  switch (action.type) {
    case SET_PIECES:
      return action.payload.pieces
    default:
      return state
  }
}

const tags = (state = [], action) => {
  switch (action.type) {
    case SET_TAGS:
      return action.payload.tags
    case CREATE_TAG:
      return _.uniqBy([...state, action.payload.tag], 'name')
    case DESTROY_TAG:
      return state.map(tag => {
        if (tag.name == action.payload.tag.name) {
          return {
            destroy: true,
            ...tag
          }
        } else {
          return tag
        }
      })
    default:
      return state
  }
}

const tagList = (state = [], action) => {
  switch (action.type) {
    case SET_TAG_LIST:
      return action.payload.tagList
    case CREATE_TAG:
      return _.uniqBy([...state, action.payload.tag], 'name')
    default:
      return state
  }
}

const entities = combineReducers({
  artists,
  performances,
  pieces,
  tags,
  tagList,
})

const id = (state = null, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return action.payload.id
    case HIDE_MODAL:
      return null
    default: 
      return state
  }
}

const form = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MODAL_ASSOCIATED_ARTIST:
      return {
        ...state,
        associatedArtist: {
          ...state.associatedArtist,
          ...action.payload,
        }
      }
    case DESTROY_MODAL_ASSOCIATED_ARTIST:
      return {
        associatedArtist: { }
      }
    case HIDE_MODAL:
      return {
        associatedArtist: { }
      }
    default:
      return state
  }
}

const modal = combineReducers({
  id,
  form,
})

const display = combineReducers({
  modal,
})

// Paths are only set at load-time
const paths = (state = {}, action) => state

// Page is only set at load-time
const page = (state = {}, action) => state

export default combineReducers({
  entities,
  display,
  paths,
  page,
})

