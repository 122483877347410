import React, { useState } from 'react'
import { DateTime } from 'luxon'

const CalendarSquare = ({ date, events }) => {
  if (!events || !events[0]) {
    return (
      <li>
        <div className="date">
          {date?.day}
        </div>
      </li>
    )
  }

  const bgImage = events[0].croppedImage
  const mainLink = `/events/${events[0].slug}`
  return (
    <li>
      <div className="date">
        {date?.day}
      </div>
      <div className="bg-image" style={{backgroundImage: `url('${bgImage}')`}} />
      <div className="event-title">
        {
          events ? (
            events.sort((a, b) => {
              return a
            }).map(e => {
              return (
                <div key={e.id} className="mt-1 pt-1 border-top">
                  <a href={`/events/${e.slug}`}>
                    {e.thisEventTime.toFormat('h:mma').toLowerCase()}: {e.title}
                  </a>
                  <div className="d-block my-2">
                    {
                      e.formattedTags.map(t => {
                        const tagLink = `/tags/${t.slug}`
                        return (
                          <div key={t.slug} className="badge badge-light badge-secondary-outline mt-1 mr-1 d-inline">
                            <a href={tagLink}>
                              {t.name}
                            </a>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          ) : null
        }
      </div>
    </li>
  )
}

export default CalendarSquare
