import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'

const TipPage = ({apiKey, successUrl, cancelUrl, tipPrice}) => {
  const [amount, setAmount] = useState(10)
  const [status, setStatus] = useState("")

  const stripePromise = loadStripe(apiKey)

  const getCheckoutId = async (event) => {
    const stripe = await stripePromise;
    const result = await stripe.redirectToCheckout({
      lineItems: [{
        price: tipPrice,
        quantity: amount
      }],
      mode: 'payment',
      successUrl: successUrl,
      cancelUrl: cancelUrl,
    })

    if (result.error) {
      setStatus(result.error.message)
    }
  }

  return (
    <div>
      {
        status ? <p className="alert">{status}</p> : null
      }
      <div className="input-group my-2">
        <div className="input-group-prepend">
          <span className="input-group-text">$</span>
        </div>
        <input type="number" 
          value={amount} 
          min="0" 
          data-number-to-fixed="2" 
          data-number-stepfactor="100" 
          className="form-control form-control-lg" 
          onChange={ev => setAmount(parseInt(ev.target.value))} 
        />
        <div className="input-group-append">
          <span className="input-group-text">.00</span>
        </div>
      </div>
      <div className="btn btn-lg btn-block btn-outline-secondary" onClick={getCheckoutId}>
        Leave Tip (Thank you!)
      </div>
    </div>
  )
}

export default TipPage
