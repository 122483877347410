import { createSelector } from 'reselect'

import { entities } from '!/selectors/entities'

export const artists = createSelector(
  entities,
  (ents) => {
    return ents.artists
  }
)

export const artistsByName = createSelector(
  artists,
  (arts) => {
    let out = { }

    for (let artist of arts) {
      out[artist.name] = artist
    }

    return out
  }
)

export const artistsById = createSelector(
  artists,
  (arts) => {
    let out = { }

    for (let artist of arts) {
      out[artist.id] = artist
    }

    return out
  }
)

