import get from 'lodash/get'

let handleFormChange = function(field) {
  return (ev) => {
    let newState = {}
    newState[field] = ev.target.value
    this.setState(newState)
  }
}

let submit = async function(ev) {
  ev.preventDefault()
  let { token } = await this.props.stripe.createToken({name: 'Name'})

  if (token && token.id) {
    this.charge({
      token: token.id,
    })
  }
}

let createFestivalPassOrder = async function(ev) {
  ev.preventDefault()

  this.setState({ inProgress: true })

  let response = await fetch('/stripe/orders/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': this.props.authenticityToken,
    },
    body: JSON.stringify({
      items: [
        {
          type: 'sku',
          parent: 'sku_digital_alchemy_pass',
          quantity: this.state.quantity,
        }
      ],
      fname: this.state.fname,
      lname: this.state.lname,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      email: this.state.email,
    })
  })

  if (response.ok) {
    let body = await response.json()
    this.setState({
      error: "", 
      success: "",
      order: body.order,
      inProgress: false,
    })
  } else {
    let body = await response.json()
    this.setState({
      error: body.message, 
      success: "",
      inProgress: false,
    })
  }
}

let charge = async function(data) {
  this.setState({ inProgress: true })

  let response = await fetch('/stripe/charge', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': this.props.authenticityToken,
    },
    body: JSON.stringify({ 
      amount: this.state.amount,
      fname: this.state.fname,
      lname: this.state.lname,
      line1: this.state.line1,
      line2: this.state.line2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      order: get(this.state, 'order.id', undefined),
      email: this.state.email || data.email,
      token: data.token,
    }),
  })

  if (response.ok) {
    let body = await response.json()
    this.setState({
      error: "", 
      success: body.message,
      inProgress: false,
    })
  } else {
    let body = await response.json()
    this.setState({
      error: body.message, 
      success: "",
      inProgress: false,
    })
  }
}

let chargeOrder = async function() {
  this.setState({ inProgress: true })

  let { token } = await this.props.stripe.createToken({name: 'Name'})

  if (token && token.id) {
    let response = await fetch('/stripe/orders/charge', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this.props.authenticityToken,
      },
      body: JSON.stringify({
        order: {
          id: this.state.order.id,
        },
        email: this.state.email,
        token: token.id,
      })
    })

    if (response.ok) {
      let body = await response.json()
      this.setState({
        error: "", 
        success: body.message,
        inProgress: false,
        complete: true,
      })
    } else {
      let body = await response.json()
      this.setState({
        error: body.message, 
        success: "",
        inProgress: false,
        complete: false,
      })
    }
  } else {
    this.setState({
      error: "Could not charge card. Try another card or email andrew@indexical.org.", 
      success: "",
      inProgress: false,
      complete: false,
    })
  }
}

export { submit, charge, chargeOrder, handleFormChange, createFestivalPassOrder }
