import 'whatwg-fetch'

import _ from 'lodash'
import { DateTime } from 'luxon'
import '!/tools/lodashMixins'
import { SET_MONTH, SET_EVENTS } from '!/actions/calendarActions'

export default (month) => {
  return async (dispatch, getState) => {
    try {
      const response = await fetch(`/events/calendar.json?year=${month.year}&month=${month.month}`, {
        method: "GET",
        credentials: "include",
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (response.ok || response.status == 304) {
        const events = _.deeply(_.mapKeys)
          (await response.json(), (v, k) => _.camelCase(k))

        dispatch({
          type: SET_EVENTS,
          payload: {
            events: events,
          }
        })
      }
    } catch(error) {
      window.Rollbar.error("Error fetching events: ", events)
    }
  }
}

