import { connect } from 'react-redux'

import TagsTable from '!/components/TagsTable'
import * as selectors from '!/selectors/tags'
import { makeCreateTag } from '!/thunks/makeCreateTag'
import { CREATE_TAG, DESTROY_TAG } from '!/actions/performanceTableActions'

const mapStateToProps = (state, props) => {
  const tags = selectors.tags(state)
  const tagList = selectors.tagList(state)

  return {
    tags,
    tagList,
  }
}

const mapDispatchToProps = (dispatch, props) => {
  const createTag = name => (event => dispatch(makeCreateTag(name, event)))

  const destroyTag = (tag) => {
    return () => {
      dispatch({
        type: DESTROY_TAG,
        payload: {
          tag: {
            name: tag.name,
          },
        }
      })
    }
  }

  return {
    createTag,
    destroyTag,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagsTable)
