import { connect } from 'react-redux'
import PerformanceTable from '!/components/PerformanceTable'

import * as selectors from '!/selectors/performances'

const mapStateToProps = (state, props) => {
  let performanceIds = selectors.visiblePerformanceIds(state)

  return {
    performanceIds,
  }
}

export default connect(mapStateToProps)(PerformanceTable)

