import {
  CREATE_TAG,
} from '!/actions/performanceTableActions'
import * as selectors from '!/selectors/tags'

export const makeCreateTag = (name, event) => {
  event.preventDefault()
  return (dispatch, getState) => {
    const state = getState()
    const tags = selectors.tagsByName(state)
    const tag = tags[name]

    dispatch({
      type: CREATE_TAG,
      payload: {
        tag: tag || { name: name },
      }
    })
  }
}
