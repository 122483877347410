import React, { useState } from 'react'

const QuestionForm = ({authenticityToken, eventId}) => {
  const [asker, setAsker] = useState("")
  const [body, setBody] = useState("")
  const [working, setWorking] = useState(false)
  const [message, setMessage] = useState("")
  const [status, setStatus] = useState("")

  const handleSubmit = async (ev) => { 
    setWorking(true)
    ev.preventDefault()

    const queryBody = {
      authenticity_token: authenticityToken,
      utf8: "✓",
      question: {
        event_id: eventId,
        asker: asker,
        body: body,
      }
    }

    const response = await fetch("/questions.json", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(queryBody),
    })

    setWorking(false)

    if (response.ok) {
      setMessage("Question sent!")
      setAsker("")
      setBody("")
      setStatus("alert-success")
    } else {
      setMessage("Error sending question. Name and question text required.")
      setStatus("alert-danger")
    }

    return false
  }

  if (working) {
    return (
      <div>
        <h2>Sending question...</h2>
      </div>
    )
  } else {
    return (
      <div>
        <h2>Ask the artists</h2>
        <p>
          Sometimes we have a post-concert Q&A. If you'd like to ask a
          question but don't want to join the Twitch chat, you can use this
          form:
        </p>
        {
          message ? <p className={`alert ${status}`}>{message}</p> : null
        }
        <form>
          <div className="form-group">
            <label htmlFor="asker">Your Name</label>
            <input type="string" name="asker" value={asker} 
              onChange={e => setAsker(e.target.value)} 
              className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="body">Your Question</label>
            <textarea type="text" name="body" value={body}
              onChange={e => setBody(e.target.value)}
              className="form-control" rows={5} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block btn-lg btn-outline-secondary" onClick={handleSubmit}>
              Submit Question
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default QuestionForm
